<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useGuidesStore } from '~/stores/guides.store'
import { useMetaStore } from '~/stores/meta.store'
import Country from './components/country.vue'
import Details from './components/details.vue'

const route = useRoute();
const guideStore = useGuidesStore()
const metaStore = useMetaStore();
const result = ref();

const fetchData = async () => {
	const page = route.query.page || 1;
	const slug = route.params.location as string;

	return await guideStore.fetchAreaGuideCities(slug);
}

result.value = await fetchData();

if (!result.value?.model) {
	showError({
		statusCode: 404,
		statusMessage: "Page Not Found"
	})
}
metaStore.setMeta(result.value?.meta);
</script>

<template>
	<LayoutAppHead></LayoutAppHead>
	<NuxtLayout name="desktop-guides">
		<template v-if="result.data && result.model === 'Country'">
			<Country :data="result.data" :country="result.country" />
		</template>
		<template v-else-if="result.data && result.model === 'Details'">
			<Details :data="result.data" />
		</template>
		<template v-else>
			<div class="text-center pb-10">
				<div class="text-2xl py-12">
					{{ $t('client.no_guides') }}
				</div>
				<a class="btn btn-primary p-3" href="/">{{ $t('client.back_to_home') }}</a>
			</div>
		</template>
	</NuxtLayout>
</template>